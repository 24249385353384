@plugin "./less-plugin";
@import url(./container.less);

.App {
  --width: 2160px;
  --height: 1080px;
  /** 缩放比例 **/
  --scale: var(--width) / 2160;
  --headerHeight: t(70);
  --bodyHeight: calc(var(--height) - var(--headerHeight));
  width: var(--width);
  height: var(--height);
  background-color: rgba(1, 12, 25, 1);
  overflow: hidden;
  position: relative;

  .cache-area {
    position: absolute;
    left: 0;
    right: 0;
    bottom: calc(-100vh - 200px);
    display: flex;
    flex-wrap: wrap;

    img {
      width: 100px;
      height: 100px;
    }
  }
}

.bg1 {
  width: var(--width);
  height: var(--height);
  position: relative;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.bg2 {
  width: var(--width);
  height: var(--height);
  background-size: 100% 100%;
  background-position: 0 0;
  background-repeat: no-repeat;
  position: relative;
}

.bg3 {
  width: t(1557);
  height: t(19);
  position: absolute;
  top: t(75);
  right: 0;
}

.main {
  width: var(--width);
  height: var(--height);
  position: absolute;
  top: 0;
  left: 0;

  .loading {
    width: var(--width);
    height: var(--bodyHeight);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
  
    .process {
      width: t(564);
      height: t(44);
      border-radius: t(30);
      margin-top: t(16);
      margin-bottom: t(8);
      display: flex;
      justify-content: space-between;
      position: relative;

      .left {
        width: t(28);
        height: t(44);
        border-top: t(4) solid rgba(45, 155, 252, 1);
        border-left: t(4) solid rgba(45, 155, 252, 1);
        border-bottom: t(4) solid rgba(45, 155, 252, 1);
      }

      .right {
        width: t(28);
        height: t(44);
        border-top: t(4) solid rgba(45, 155, 252, 1);
        border-right: t(4) solid rgba(45, 155, 252, 1);
        border-bottom: t(4) solid rgba(45, 155, 252, 1);
      }

      .content {
        width: t(540);
        height: t(20);
        position: absolute;
        top: t(12);
        left: t(12);
        display: flex;
        justify-content: space-between;

        .item {
          width: t(10);
          height: t(20);
          background-color: rgba(255, 255, 255, 0.15);
        }
      }
    }

    .loading-text {
      color: rgba(45, 155, 252, 1);
      font-size: t(18);
      letter-spacing: t(3);
    }

    .loading-process {
      color: rgba(45, 155, 252, 1);
      font-size: t(24);
      font-weight: bold;
      letter-spacing: t(4);
    }
  }
}

.container {
  width: var(--width);
  height: var(--bodyHeight);
}

.yAxis {
  position: absolute;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: rgba(111,112,121,1);
  font-size: t(12);
  text-align: right;
  padding-right: t(8);
  font-family: sans-serif;
}

.yAxis-label {
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  animation: enterFrom var(--sec) forwards;
}

@keyframes enterFrom {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
