@plugin "../../less-plugin";

.header {
  width: var(--width);
  height: t(91);
  position: relative;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  position: relative;
  z-index: 9;

  .title {
    width: t(646);
    height: t(90);
    background-size: 100% 100%;

    span {
      text-shadow: 0px 1px 1px rgba(255, 255, 255, 0.1);
      background-image: linear-gradient( 180deg, rgba(255, 255, 255, 1) 0.204873%, rgba(180, 224, 255, 1) 67.810315%, rgba(16, 118, 191, 1) 100% );
      position: absolute;
      left: t(40);
      top: t(20);
      height: t(50);
      overflow-wrap: break-word;
      color: rgba(255, 255, 255, 1);
      font-size: t(36);
      letter-spacing: 1px;
      font-family: AlibabaPuHuiTi-Bold;
      font-weight: 700;
      text-align: left;
      white-space: nowrap;
      line-height: t(50);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .tabs {
    display: flex;
    flex: 1;
    justify-content: flex-start;
    gap: t(20);
    padding: t(12);

    .split {
      width: t(16);
      height: t(28);
      margin-top: t(9);
    }
  }

  .localInfo {
    width: t(450);
    height: t(70);
    padding-top: t(14);
    padding-right: t(58);
    padding-bottom: t(8);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .time {
      height: t(22);
      line-height: t(22);
      text-shadow: 0px 0px t(10) rgba(0, 108, 255, 1);
      overflow-wrap: break-word;
      color: rgba(255, 255, 255, 1);
      font-size: t(20);
      text-align: right;
      white-space: nowrap;
    }

    .more {
      height: t(22);
      line-height: t(22);
      text-shadow: 0px 0px t(10) rgba(0, 108, 255, 1);
      overflow-wrap: break-word;
      color: rgba(255, 255, 255, 1);
      font-size: t(16);
      white-space: nowrap;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: t(12);

      .weatherIcon {
        width: t(16);
        height: t(17);
      }

      .split {
        width: 1px;
        height: t(14);
      }
    }
  }

  .light {
    width: t(2160);
    height: t(91);
    position: absolute;
    background-position: center 0;
    background-repeat: no-repeat;
    background-size: 100%;
  }
}
