@plugin "../../less-plugin";

.tabButtons {
  width: max-content;
  height: t(32);
  display: flex;
  gap: t(8);
  justify-content: flex-start;
}

.tabButtons .btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 t(8);
  font-size: t(16);
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
  background-color: rgba(45, 155, 252, 0.15);
  border: 2px solid transparent;
}

.tabButtons .active {
  color: rgba(120, 255, 183, 1);
  font-weight: 700;
  background-color: rgba(3, 76, 48, 0.078);
  border: t(2) solid rgba(120, 255, 183, 0.52);
}
