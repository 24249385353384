@plugin "../../less-plugin";

.card {
  width: t(500);
}

.card .card-header {
  display: flex;
  justify-content: space-between;
}

.card .card-title {
  min-width: t(500);
  height: t(32);
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 0.9);
  font-size: t(18);
  letter-spacing: 1px;
  font-family: AlibabaPuHuiTi-Regular;
  white-space: nowrap;
  background-position-x: t(28);
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  font-weight: 400;
  background-size: contain;
}

.card .card-extra {
  display: flex;
  justify-content: flex-end;
}

.card .card-title span {
  opacity: 0;
  animation: hide2show 2s forwards;
}

.card .card-title .card-title-icon {
  width: t(32);
  height: t(32);
  margin-right: t(18);
  animation: icon-spin 2s;
}

.card .card-content {
  width: t(500);
}

@keyframes icon-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes hide2show {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.6;
  }
}
