@plugin "../../less-plugin";

.flowLight {
  position: absolute;
  top: 0;
  left: 0;
}

circle {
  animation: zoomInOut 3s infinite alternate;
}
@keyframes zoomInOut {
  0% { r: 300px; }
  50% { r: 250px; }
  75% { r: 50px; }
  100% { r: 0px; }
}
