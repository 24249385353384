@plugin "../../less-plugin";

.alert {
  width: min-content;
  height: t(44);
  position: relative;
  padding-left: t(12);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .category {
    width: max-content;
    height: t(20);
    font-size: t(14);
    color: rgba(255, 255, 255, 0.4);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: t(10);

    .percent {
      font-size: t(16);
      font-weight: bold;
    }
  }

  .value {
    width: max-content;
    height: t(20);
    font-size: t(16);
    color: rgba(255, 255, 255, 0.9);
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: t(4);
  }

  .value::after {
    content: attr(data-suffix);
    font-size: t(12);
  }
}

.alert::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: t(2);
  background-color: var(--alert-color);
}
