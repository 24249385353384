.containerLeft {
  width: t(634);
  padding-left: t(60);
  position: absolute;
  top: t(120);
  left: 0;

  .chargeTrend {
    width: t(425);

    .label-row {
      margin-top: t(12);
      margin-bottom: t(8);
      display: flex;
      justify-content: space-between;
    }

    #chartDom {
      position: relative;
      width: t(445);
      height: t2(124);
    }
  }

  .carTypePie {
    margin-top: t(24);
    margin-left: t(34);
    width: t(370);
    display: flex;
    justify-content: space-between;

    .pie-outer {
      width: t(82);
      height: t(82);
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid rgba(45, 155, 252, 0.1);
      border-radius: 50%;
    }
    
    .pie-label {
      font-size: t(14);
      font-weight: 400;
      color: #D0DEEE;
      text-align: center;
      margin-top: t(8);
    }

    .pieDom {
      position: relative;
      width: t(74);
      height: t(74);
    }
  }

  .carEmpty {
    margin-top: t(24);
    width: t(436);
    height: t(110);
    background-color: rgba(0, 128, 254, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: t(64);
      height: t(41);
    }

    span {
      margin-top: t(15);
      font-size: t(14);
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: rgba(255,255,255,0.5);
    }
  }

  #chargeAnalysisChart {
    position: relative;
    width: t(436);
    height: t2(140);
  }

  .empty {
    position: relative;
    width: t(436);
    height: t(140);
    background-color: rgba(0, 128, 254, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: t(64);
      height: t(41);
    }

    span {
      margin-top: t(15);
      font-size: t(14);
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: rgba(255,255,255,0.5);
    }
  }

  .payTypePie {
    width: 100%;
    position: relative;

    .row {
      position: absolute;
      top: t(146);
      width: t(436);
      display: flex;
      justify-content: space-between;
    }

    #payAnalysisChart {
      width: t(480);
      height: t(240);
      top: t(-55);
      left: t(50);
      position: absolute;
      transform: scale(0.2);
      opacity: 0;
    }
  }

  .payTypeEmpty {
    margin-top: t(24);
    width: t(436);
    height: t(170);
    background-color: rgba(0, 128, 254, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: t(64);
      height: t(41);
    }

    span {
      margin-top: t(15);
      font-size: t(14);
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: rgba(255,255,255,0.5);
    }
  }

  .deviceData {
    display: flex;
    margin-top: t(24);
    gap: t(24);

    .box {
      width: t(208);
      height: t(100);
      background-color: rgba(45, 155, 252, 0.1);
      display: flex;
      align-items: center;
      gap: t(18);
      position: relative;

      img {
        width: t(128);
        height: t(95);
        position: relative;
        top: t(19);
        left: t(-24);
      }

      .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: t(8);
        position: absolute;
        left: t(80);

        .title {
          color: rgba(255, 255, 255, 0.4);
          font-size: t(16);
          font-family: AlibabaPuHuiTi-Regular;
        }

        .value {
          color: rgba(255, 255, 255, 0.9);
          font-size: t(28);
          font-weight: bold;
        }
      }
    }
  }

  .power {
    width: t(406);
    height: t(180);
    margin-top: t(24);
    display: flex;

    .chart {
      min-width: t(180);
      height: t(180);
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      #powerChart {
        width: t(136);
        height: t(136);
      }

      .power-bg {
        width: t(180);
        height: t(180);
        position: absolute;
        top: 0;
        left: 0;
        animation: imgRotate 1.5s forwards;
      }

      .power-inset-bg {
        width: t(80);
        height: t(80);
        position: absolute;
        top: t(50);
        left: t(50);
      }
    }

    .data {
      display: flex;
      flex-direction: column;
      padding-left: t(28);

      .item {
        height: t(90);
        display: flex;
        align-items: center;
      }
    }
  }

  .powerChange {
    width: t(436);
    margin-top: t(24);

    .devices-and-power {
      width: t(436);
      height: t(60);
      display: flex;

      .item {
        width: t(218);
        height: t(60);
        display: flex;
        gap: t(8);
        position: relative;

        img {
          width: t(94);
          height: t(83);
          position: absolute;
          top: t(2);
          left: t(-17);
        }
  
        .content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: t(8);
          position: absolute;
          left: t(71);
  
          .title {
            color: rgba(255, 255, 255, 0.4);
            font-size: t(16);
            font-family: AlibabaPuHuiTi-Regular;
          }
  
          .value {
            color: rgba(255, 255, 255, 0.9);
            font-size: t(28);
            font-weight: bold;

            .suffix {
              font-size: t(14);
            }
          }
        }
      }
    }

    .labelArea {
      width: t(436);
      margin-top: t(16);
      display: flex;
      justify-content: space-between;

      .mid {
        display: flex;
        gap: t(16);
      }
    }

    #deviceAndPowerChart {
      width: t(436);
      height: t2(230);
      margin-top: t(12);
      position: relative;
    }
  }
}

.containerMid {
  width: t(1030);
  position: absolute;
  top: t(100);
  left: t(634);

  .midValue {
    width: t(891);
    height: t(110);
    margin-top: t(32);
    display: flex;
    justify-content: space-between;
  }

  .map {
    width: t(1030);
    height: t(373);
    margin-top: t(100);
    position: relative;

    .map-bg {
      width: t(778);
      position: absolute;
      left: t(28);
    }

    .position-icon {
      position: absolute;
      width: t(32.96);
      height: t(64);
      background-position: 0 0;
      background-size: 100%;
      background-repeat: no-repeat;
      animation: position-icon-scroll 3s steps(89, start) 0ms forwards;
    }

    @keyframes position-icon-scroll {
      0% {
        background-position-y: 0;
        transform: scale(0.3);
      }
      20% {
        transform: scale(1);
      }
      100% {
        background-position-y: t(-5696);
      }
    }
  }

  .sort-body {
    width: t(891);
    display: flex;
    justify-content: space-between;
    margin-top: t(18);

    .sort-item {
      width: t(208);
      height: t(214);
      display: flex;
      flex-direction: column;
      align-items: center;
      background-position: 0 0;
      background-repeat: no-repeat;
      background-size: contain;

      .sort-award {
        width: t(80);
        height: t(50);
        margin-top: t(20);
        margin-bottom: t(10);
      }

      .sort-number {
        font-size: t(20);
        text-align: center;
      }

      .sort-name {
        font-size: t(18);
        margin: t(10);
        max-height: t(50);
        text-align: center;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        display: -webkit-box;
        font-weight: bold;
      }

      .sort-value {
        font-size: t(24);
        font-weight: bold;
        text-align: center;
      }

      .sort-value::after {
        content: attr(data-suffix);
        font-size: t(12);
      }
    }

    .sort-item2 {
      width: t(208);
      height: t(97);
      border-radius: t(8);
      background-color: rgba(0, 128, 254, 0.1);
      padding: 0 t(20);
      position: relative;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: t(8);

      .sort-badge-ctx {
        position: absolute;
        top: t(2);
        left: t(5);
        color: rgba(255,255,255,0.9);
        font-size: t(12);
      }

      .sort-name2 {
        color: rgba(179, 214, 249, 1);
        font-size: t(16);
        overflow: hidden;
        white-space: nowrap;
        font-weight: bold;
        text-overflow: ellipsis;
      }

      .sort-value2 {
        color: rgba(255, 255, 255, 1);
        font-weight: bold;
        font-size: t(20);
      }

      .sort-value2::after {
        content: attr(data-suffix);
        font-size: t(12);
      }
    }

    .sort-item2::before {
      content: '';
      position: absolute;
      top: t(-20);
      left: t(-20);
      background-color: rgba(74, 151, 217, 0.44);
      width: t(40);
      height: t(40);
      transform: rotate(45deg);
    }
  }

  .devicesStatus {
    width: t(740);
    padding-top: t(25);
    display: flex;
    position: relative;

    .device-bg {
      position: absolute;
      top: t(12);
      left: t(185.5);
      width: t(369);
      height: t(300);
      background-position: 0 0;
      background-repeat: no-repeat;
      background-size: 100%;
      animation: device-bg-scroll 5s steps(89, start) 0ms infinite normal backwards;
    }

    @keyframes device-bg-scroll {
      0% {
        background-position-y: 0;
      }
      100% {
        background-position-y: t(-26700);
      }
      
    }

    .box {
      width: t(370);
      height: t(216);
      padding: t(14) 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;

      .item {
        width: t(248);
        height: t(44);
        color: rgba(255, 255, 255, 1);
        font-size: t(16);
        font-weight: 700;
        display: flex;
        align-items: center;
        padding-left: t(60);
        justify-content: flex-start;
        gap: t(20);
        transform: translateX(var(--start));
        animation: slide 1.5s forwards;
        background-repeat: no-repeat;
        background-size: 100%;

        .count {
          font-size: t(24);
        }
      }
    }

    .box-right {
      align-items: flex-start;

      .item {
        padding-left: 0;
        padding-right: t(60);
        justify-content: flex-end;
      }
    }

    @keyframes slide {
      0% {
        transform: translateX(var(--start));
        opacity: 0;
      }
      40% {
        opacity: 1;
      }
      100% {
        transform: translateX(var(--end));
      }
    }
  }
}

.containerRight {
  width: t(516);
  position: absolute;
  top: t(120);
  right: 0;

  .userCount {
    display: flex;
    margin-top: t(16);
    gap: t(16);

    .quantity {
      width: t(90);
      position: relative;

      .quantity-bg {
        width: t(135);
        height: t(135);
        position: absolute;
        top: t(-22.5);
        left: t(-22.5);
        background-position: 0 0;
        background-repeat: no-repeat;
        background-size: 100%;
        animation: quantity-bg-scroll 5s steps(87, start) 0ms infinite normal backwards;
      }

      @keyframes quantity-bg-scroll {
        0% {
          background-position-y: 0;
        }
        100% {
          background-position-y: t(-11745);
        }
      }

      .quantity-label {
        font-size: t(14);
        font-weight: bold;
        color: rgba(255,255,255,1);
        overflow-wrap: break-word;
        text-shadow: 0px 1px 1px rgba(255, 255, 255, 0.1);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        background-image: linear-gradient( 180deg, rgba(255, 255, 255, 1) 0, rgba(180, 224, 255, 1) 67.810315%, rgba(16, 118, 191, 1) 100% );
        position: absolute;
        top: t(115);
        width: t(90);
        text-align: center;
      }

      .quantity-value {
        font-size: t(24);
        font-weight: bold;
        color: rgba(255,255,255,0.9);
        position: absolute;
        top: t(5);
        width: t(90);
        text-align: center;
      }
    }

    #userCountChart {
      position: relative;
      width: t(330);
      height: t2(120);
    }
  }

  .userCountEmpty {
    margin-top: t(24);
    width: t(436);
    height: t(140);
    background-color: rgba(0, 128, 254, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: t(64);
      height: t(41);
    }

    span {
      margin-top: t(15);
      font-size: t(14);
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: rgba(255,255,255,0.5);
    }
  }

  .chargeCount {
    display: flex;
    gap: t(16);
    margin-top: t(16);

    .quantity {
      width: t(90);
      position: relative;

      .quantity-bg {
        width: t(135);
        height: t(135);
        position: absolute;
        top: t(-22.5);
        left: t(-22.5);
        background-position: 0 0;
        background-repeat: no-repeat;
        background-size: 100%;
        animation: quantity-bg-scroll 5s steps(87, start) 0ms infinite normal backwards;
      }

      @keyframes quantity-bg-scroll {
        0% {
          background-position-y: 0;
        }
        100% {
          background-position-y: t(-11745);
        }
      }

      .quantity-label {
        font-size: t(14);
        font-weight: bold;
        color: rgba(255,255,255,1);
        overflow-wrap: break-word;
        text-shadow: 0px 1px 1px rgba(255, 255, 255, 0.1);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        background-image: linear-gradient( 180deg, rgba(255, 255, 255, 1) 0, rgba(180, 224, 255, 1) 67.810315%, rgba(16, 118, 191, 1) 100% );
        position: absolute;
        top: t(115);
        width: t(90);
        text-align: center;
      }

      .quantity-value {
        font-size: t(24);
        font-weight: bold;
        color: rgba(255,255,255,0.9);
        position: absolute;
        top: t(5);
        width: t(90);
        text-align: center;
      }
    }

    #chargeCountChart {
      position: relative;
      width: t(330);
      height: t2(120);
    }
  }

  .chargeTypePie {
    width: 100%;
    position: relative;

    .row {
      position: absolute;
      top: t(148);
      width: t(436);
      display: flex;
      justify-content: space-between;
    }

    #chargeTypeChart {
      width: t(480);
      height: t(240);
      top: t(-64);
      left: t(50);
      position: absolute;
      transform: scale(0.2);
      opacity: 0;
      overflow: hidden;
    }
  }

  .chargeTypeBarArea {
    width: t(445);
    margin-top: t(24);
    position: absolute;
    top: t(235);

    #chargeTypeBar {
      position: relative;
      width: t(445);
      height: t2(120);
    }
  }

  .devicePercent {
    margin-top: t(16);
    margin-left: t(35);
    display: flex;
    flex-direction: column;
    gap: t(20);

    .box {
      display: flex;
      gap: t(52);
      height: t(100);

      .item {
        width: t(100);
        height: t(100);
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        border-radius: 50%;
        border: 1px solid rgba(45, 155, 252, 0.1);

        .devicePercentPie {
          width: t(80);
          height: t(80);
        }

        img {
          width: t(100);
          height: t(100);
          position: absolute;
          top: 0;
          left: 0;
          animation: imgRotate 1.5s forwards;
        }
      }
    }
  }

  .deviceRecords {
    width: t(458);
    display: flex;
    flex-direction: column;
    gap: t(16);
    margin-top: t(30);

    .body {
      width: t(436);
      height: t(196);
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;

      .device-status-bg {
        width: t(98);
        height: t(98);
        background-position: 0 0;
        background-repeat: no-repeat;
        background-size: 100%;
        position: absolute;
        animation: device-status-bg-scroll 5s steps(89, start) 0ms infinite normal backwards;
      }

      @keyframes device-status-bg-scroll {
        0% {
          background-position-y: 0;
        }
        100% {
          background-position-y: t(-8722);
        }
      }

      .list {
        width: t(327);
        height: t(196);
        overflow: hidden;
        position: relative;

        .item {
          display: flex;
          position: absolute;
          width: t(327);
          height: t(36);
          background-color: rgba(0, 128, 254, 0.05);
          align-items: center;
          margin-bottom: t(4);
          padding: 0 t(10);
          overflow: hidden;
          top: t(-40);
          left: 0;
          transform: translateY(var(--start));

          span {
            font-size: t(14);
            font-family: AlibabaPuHuiTi;
            font-weight: 400;
            color: #FFFFFF;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          span:nth-child(1) {
            width: t(126);
            margin-right: t(10);
          }

          span:nth-child(2) {
            width: t(80);
            margin-right: t(5);
          }

          span:nth-child(3) {
            width: t(36);
            margin-right: t(5);
          }

          span:nth-child(4) {
            width: t(45);
            text-align: right;
          }
        }

        .item::before {
          content: '';
          position: absolute;
          top: t(-3);
          left: t(-3);
          width: t(6);
          height: t(6);
          transform: rotate(45deg);
          background-color: rgba(0, 128, 254, 1);
        }

        @keyframes scrollY {
          0% {
            transform: translateY(var(--start));
          }
          100% {
            transform: translateY(var(--end));
          }
        }
      }
    }
  }

  .terminalPower {
    width: t(408);
    height: t(236);
    margin-top: t(28);
    margin-left: t(14);
    position: relative;

    .terminalPowerPie {
      width: t(180);
      height: t(180);
      position: absolute;
      top: 0;
      right: t(54);
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: t(118);
        height: t(118);
        animation: imgRotate 1.5s forwards;
      }

      .title {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: t(14);
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: rgba(255,255,255,0.4);
      }
    }

    .row {
      width: t(408);
      height: t(44);
      position: absolute;
      left: 0;
      bottom: 0;
      display: flex;
      justify-content: space-between;
    }
  }
}

.threeD-pie-bottom {
  position: absolute;
  width: t(180);
  height: t(100);
  border: 1px solid rgba(36,91,122,0.50);
  top: t(84);
  left: t(149);
  border-radius: 50% 50% 50% 50% / 50% 50% 50% 50% ;
  z-index: -1;
  box-shadow: inset 0 0 0 t(2) rgba(36,91,122,0.25);
  animation: ease-in threeD-pie-bottom-animation 2s 1;
}

@keyframes threeD-pie-bottom-animation {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  95% {
    transform: scale(1.7);
    opacity: 0;
  }
  96% {
    transform: scale(1);
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes imgRotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(720deg);
  }
}
