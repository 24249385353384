@plugin "../../less-plugin";

.label {
  width: max-content;
  display: flex;
  align-items: center;
  height: t(20);
  font-size: t(14);
  font-weight: 400;
  padding: 0 t(8);
  color: rgba(255,255,255,0.4);
  border-left: 1px solid rgba(255,255,255,0.25);
  background-color: rgba(45,155,252,0.08);
  gap: t(4);
}