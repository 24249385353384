@plugin "../../../less-plugin";

.tabButton {
  width: t(155);
  height: t(41);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-shadow: 0px 2px 5px rgba(44, 70, 117, 0.5);
  overflow-wrap: break-word;
  font-size: t(20);
  letter-spacing: 2px;
  font-family: AlibabaPuHuiTi-Bold;
  white-space: nowrap;
  color: #9FD1FF;
  font-weight: 400;
  position: relative;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 0 t(-1);
}

.tab-active {
  color: #78FFB7;
  font-weight: bold;
}

.tabButton .tab-btn-outer-left {
  width: t(18);
  height: t(41);
}

.tabButton .tab-btn-outer-right {
  width: t(18);
  height: t(41);
}

.tabButton .tab-btn-inner-left {
  width: t(6);
  height: t(10);
  position: absolute;
  left: t(10);
}

.tabButton .tab-btn-inner-right {
  width: t(6);
  height: t(10);
  position: absolute;
  right: t(10);
}

.tabButton .tab-btn-bottom {
  width: t(78);
  height: 2px;
  position: absolute;
  bottom: -1px;
  left: t(38)
}
