@plugin "../../less-plugin";

.b-alert {
  width: t(284);
  height: t(110);
  padding-left: t(20);
  position: relative;
  background: linear-gradient(270deg, rgba(45,155,252,0) 0%, rgba(45, 155, 252, 0.4) 100%);
}

.b-alert::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: t(6);
  background-color: rgba(45, 155, 252, 1);
}

.b-alert .b-label {
  width: max-content;
  height: t(55);
  line-height: t(55);
  font-size: t(20);
  color: rgba(255, 255, 255, 0.90);
}

.b-alert .b-value {
  width: max-content;
  height: t(55);
  line-height: t(38);
  font-size: t(44);
  color: rgba(255, 255, 255, 1);
  font-weight: bold;
}
