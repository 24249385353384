@plugin "../../less-plugin";

.select {
  width: t(195);
  height: t(35);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: t(5);
  position: relative;
  left: t(361);

  .select-title {
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    cursor: pointer;
    gap: t(6);

    span {
      font-size: t(26);
      line-height: t(26);
      color: rgba(216, 240, 255, 1);
      text-shadow: 0px 0px 10px #0091FF, 0px 0px 4px #0091FF;
      white-space: nowrap;
    }

    img {
      width: t(14);
      height: t(13);
    }
  }

  .select-options {
    position: absolute;
    width: max-content;
    max-height: t(400);
    overflow-y: auto;
    display: flex;
    flex-shrink: 0;
    flex-basis: auto;
    flex-direction: column;
    background-color: rgba(1,17,45,0.9);
    top: t(35);
    z-index: 1;

    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: t(40);
      min-height: t(40);
      color: #D8F0FF;
      font-size: t(16);
      font-weight: 400;
      padding: 0 t(12);
      cursor: pointer;
      gap: t(8);

      img {
        width: t(24);
        height: t(24);
        opacity: 0;
      }
    }
  }

  .select-options::-webkit-scrollbar {
    width: t(6);
    border-radius: t(3);
    background-color: rgba(21, 154, 255, 0.2);
  }
  
  .select-options::-webkit-scrollbar-thumb {
    width: t(6);
    border-radius: t(3);
    background-color: rgba(45, 155, 252, 1);
  }
}
