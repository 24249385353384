@plugin "../../less-plugin";

.alert2 {
  width: min-content;
  height: t(52);
  position: relative;
  padding-left: t(20);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .category {
    width: max-content;
    height: t(16);
    font-size: t(16);
    color: rgba(255, 255, 255, 0.4);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: t(10);
  }

  .value {
    width: max-content;
    height: t(28);
    font-size: t(28);
    color: rgba(255, 255, 255, 0.9);
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: t(4);
  }

  .value::after {
    content: 'kW';
    font-size: t(14);
    height: t(28);
    line-height: t(35);
  }
}

.alert2::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: t(2);
  background-color: var(--alert-color);
}
